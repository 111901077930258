<template lang="pug">
.fp-sidebar-content(
  :class="{ 'with-overlap': overlap, 'closed': !openSidebar }"
  @click="(event) => closeSidebar(event)"
)
  .sidebar(
    :class="{ absolute: overlap }"
    :style="formattedSidebarStyle"
    ref='sidebar'
    v-if='organizationLoaded'
  )
    a.title(
      :href="`#/home/${organizationId}`"
      :class='{ active: isTitleActive }'
      @click.stop="goToHome"
    )
      img.ovh-logo(src="@/shared/assets/img/ovhcloud_logo_simplified_blue.png")
      span {{ $t('sidebar.title') }}

    .sub-title {{ $t('sidebar.sub_title.favorites') }}
    .favorites(@mouseleave="deactivateService('mouse')")
      a.favorite(
        v-if="hasFavorites && favoriteProjectExist(favorite.projectId)"
        v-for="favorite in favorites"
        :class="{ disabled: projectIsNotReady(favorite) }"
        v-tooltip="{ content: projectIsNotReady(favorite) ? $t('header.project_is_not_ready') : '', delay: 0 }"
        :href="getLink(favorite)"
        @click.stop="openItem(favorite)"
      )
        .icon-container
          i.fp4.project-icon(
            :class="[getProjectIconSettings(favorite.projectId).type]"
            :style="{ color: getProjectIconSettings(favorite.projectId).color }"
          )
        i.fp4.fp4-angle-right
        .name-container
          .service {{ $t(favorite.service ? `services.display_name.${favorite.service}` : 'sidebar.favorites.home') }}
          .project-name(
            v-if="favorite.projectId"
          ) {{ getProjectName(favorite.projectId) }}
        .pin-container
          i.fp4.fp4-pin-filled(
            v-tooltip="{ content: $t('home.dataplant.remove.pin_favorites'), delay: 0 }"
            @click.stop.prevent="removeFromFavorites(favorite)"
          )

      .no-elements(
        v-if="!hasFavorites"
      )
        .title-no {{ $t('sidebar.no_favorite_title') }}
        .text {{ $t('sidebar.no_favorite_text') }}

    .sub-title(@mouseleave="deactivateService('mouse')") {{ $t('sidebar.sub_title.all_service') }}
    .all-services(
      ref="all-services"
    )
      component.service(
        v-for="(service, i) in servicesList"
        v-if="!service.hidden"
        v-tooltip="{ content: service.disabled ? $t('dashboard.access.tooltip') : '', delay: 0 }"
        :is="isServiceClickable(service) ? 'a' : 'div'"
        :key="`${service.id}-${i}`"
        :ref='`sidebar-service-${service.id}`'
        :class="serviceClassList(service)"
        :href="openFromOrganization(service)"
        @mouseover="activateService('mouse', service)"
        @click.stop="(event) => activateService('click', service, event)"
      )
        img(
          :src="service.image"
          :class="service.id"
        )
        span.service-name {{ $t(service.displayName) }}
        i.fp4.fp4-lock-keyhole(
          v-if="service.disabled"
        )

  .projects-list(
    @mouseleave="deactivateService('mouse')"
    v-show="showProjectsList"
    ref='projects-list'
    v-if='organizationLoaded'
  )
    open-in-project(
      ref="open-projects"
      :from-sidebar="true"
      :from-dataplant="fromDataplant"
      :organization-id="organizationId"
      :service-active="activeService"
      :current-service='currentService'
      @close="deactivateService('click')"
      @service-wizard='(interval) => serviceHealthCheckInterval = interval'
    )
</template>

<script>
import _get from 'lodash/get'
import _cloneDeep from 'lodash/cloneDeep'
import Services from '@/core/helpers/services'
import Config from '@/shared/Config'
import OpenInProject from './OpenInProject.vue'

export default {
  components: {
    OpenInProject
  },
  props: {
    overlap: { type: Boolean, default: false },
    fromDataplant: { type: Boolean, default: false },
    styleSidebar: { type: Object, default: () => {} },
    currentService: { type: String, default: () => null }
  },
  data () {
    return {
      activeServiceMouse: null,
      activeServiceClick: null,
      config: null,
      serviceHealthCheckInterval: null,
      organizationLoaded: false
    }
  },
  computed: {
    openSidebar () {
      return this.$store.getters.OPEN_SIDE_BAR
    },
    servicesList () {
      let servicesList = []
      _cloneDeep(Services).forEach(s => {
        servicesList = servicesList.concat(s.services.filter(s => !s.hidden))
      })

      servicesList.forEach(s => {
        if (s.id === 'storage-engines' && !this.canStorageEnginesRead) {
          s.disabled = true
          s.image = require('@/core/components/Dataplant/assets/storage-engines_dark.svg')
        }
      })

      return servicesList.filter(s => {
        if (this.config?.DATA_PLATFORM_ACCESS && s.id === 'mlm') return false
        if (s.dataplatform && !this.config?.DATA_PLATFORM_ACCESS) return false
        return true
      })
    },
    projects () {
      if (this.config?.TITLE === 'CC' && !this.projectId) return this.$store.getters.CC_DATAPLANTS_BY_ORGANIZATION_ID(this.organizationId) || []
      return this.$store.getters.DATAPLANTS_BY_ORGANIZATION_ID(this.organizationId) || []
    },
    projectId () {
      return this.$route.params?.dataplantId || this.$route.params?.projectId
    },
    project () {
      if (this.projectId) return this.$store.getters.DATAPLANT_BY_ID(this.projectId)
      return null
    },
    currentUser () {
      if (this.fromDataplant) return this.$store.getters.KING_SESSION || this.$store.getters.SESSION // In CC KING_SESSION is not set
      return this.$store.getters.SESSION
    },
    favorites () {
      return this.currentUser?.preferences?.home_page_preferences?.[this.organizationId]?.favorites || []
    },
    hasFavorites () {
      if (!this.favorites) return false
      return this.favorites.length
    },
    activeService () {
      return this.activeServiceMouse || this.activeServiceClick
    },
    organizationId () {
      return this.$route.params?.organizationId || this.config?.ORGANIZATION_ID || this.project?.organization_id
    },
    filteredCurrentService () {
      if (this.currentService) {
        if (this.currentService === 'ml') return 'mlm'
        if (this.currentService === 'gab') return 'aab'
        return this.currentService
      }
      return null
    },
    formattedSidebarStyle () {
      if (this.styleSidebar?.top && !this.styleSidebar?.height) {
        return {
          ...this.styleSidebar,
          height: `calc(100% - ${this.styleSidebar.top})`
        }
      }
      return this.styleSidebar
    },
    isTitleActive () {
      if (this.$route?.name) return ['HQ Home Organization', 'HQ Home Services'].includes(this.$route.name)
      return false
    },
    organizationSession () {
      return this.$store.getters.ORGANIZATION_SESSION
    },
    canStorageEnginesRead () {
      const acl = this.config?.CMP ? this.organizationId : (this.currentService === 'iam' ? this.organizationId : true)
      return this.$acl(acl, this.organizationSession).can('core', 'storage_instance', 'read')
    },
    showProjectsList () {
      if (!this.organizationId) return false
      if (this.activeService?.id === 'iam') return true
      return this.activeService && !this.isFromOrganization(this.activeService) && this.projects && this.projects.length > 1
    }
  },
  watch: {
    openSidebar: {
      handler (val) {
        if (!val) {
          this.activeServiceMouse = null
          this.activeServiceClick = null
        }
      }
    },
    '$route' () {
      if (this.serviceHealthCheckInterval) clearInterval(this.serviceHealthCheckInterval)
    },
    async '$route.params.organizationId' (val) {
      if (val) this.config = await Config()
    },
    projects (val, oldVal) {
      if (val?.length !== oldVal?.length) {
        // Temporary fix, backend should loop through users and remove recent projects and services
        // when affected projects and services are not available anymore
        const projectsId = val.map(dp => dp.id)
        const favorites = this.currentUser?.preferences?.home_page_preferences?.[this.organizationId]?.favorites || []
        const filteredFavorites = favorites.filter(f => projectsId.includes(f.projectId))

        if (favorites.length !== filteredFavorites.length) {
          this.currentUser.update(`preferences.home_page_preferences.${this.organizationId}.favorites`, filteredFavorites)
        }
      }
    }
  },
  async mounted () {
    this.config = await Config()
    // Because of permissions in organizations listing, we now need to get individual organization with
    // the organization token
    const organizationId = this.config?.ORGANIZATION_MODE ? this.projectId : this.organizationId
    if (organizationId) await this.$store.dispatch('LOAD_ORGANIZATION', organizationId)
    this.organizationLoaded = true
  },
  methods: {
    isServiceClickable (service) {
      if (this.isFromOrganization(service)) return true
      if (this.projectId) return true // To be able to redirect to the service in current project by clicking service name
      if (this.projects && this.projects.length === 1) return true // To be able to redirect to the service in current project by clicking service name if the user have access to only 1 project
      return false
    },
    projectIsNotReady (favorite) {
      const project = this.projects.find(p => p.id === favorite.projectId)
      return !project?.global_status || project?.global_status === 'project_deploying'
    },
    getProjectName (projectId) {
      return this.projects.find(p => p.id === projectId)?.name || ''
    },
    getProjectIconSettings (projectId) {
      return this.projects.find(p => p.id === projectId)?.display_settings?.icon || {}
    },
    isFromOrganization (service) {
      // Change the type of html element depending on the service so that it can be clickable or not
      return service.fromOrganization
    },
    activateService (from = 'click', service, event) {
      if (!this.organizationId) return false
      if (from === 'click') {
        // If is has the `fromOrganization` key, the component is a direct link to it
        if (this.isFromOrganization(service)) {
          if (service.disabled) return
          window.localStorage.setItem('tracking-from', 'sidebar')
          window.localStorage.setItem('tracking-from-object', JSON.stringify({
            fromOrganizationId: this.organizationId,
            fromProjectId: this.projectId
          }))
          this.closeSidebar(null, true)
          return
        }
        // If there is no projects in the organization, open the 'create a new project' modal when the service is clicked
        if (!this.projects.length) {
          if (this.$refs['open-projects']) this.$refs['open-projects'].createProject(service)
        }
        // Due to the click listener we need to delay the opening
        setTimeout(() => {
          // Prevent empty project object if appservice and we are in an organization
          if (['app', 'api'].includes(service.id) && this.projectId === this.organizationId) return
          this.activeServiceClick = service
          this.activeServiceMouse = null
          if ((this.projects && this.projects.length === 1 && this.projects[0]) || this.projectId) {
            const project = this.projectId ? this.project : this.projects[0]
            if (this.$refs['open-projects'] && !project?.services.includes(service.id)) this.$refs['open-projects'].addService(project)
          }
          this.$nextTick(() => {
            this.displayProjectsList(service)
            if (this.$refs['open-projects']) this.$refs['open-projects'].$refs.search.setInputFocus()
          })
        }, 100)
      }
      if (from === 'mouse' && !this.activeServiceClick) {
        this.activeServiceMouse = service
        this.activeServiceClick = null
        if (!this.isFromOrganization(service)) this.$nextTick(() => this.displayProjectsList(service))
      }
    },
    deactivateService (from = 'click') {
      if (from === 'click') this.activeServiceClick = null
      if (from === 'mouse') this.activeServiceMouse = null
    },
    removeFromFavorites (favorite) {
      this.currentUser.update(`preferences.home_page_preferences.${this.organizationId}.favorites`, this.favorites.filter(f => !(f.projectId === favorite.projectId && f.service === favorite.service)))
    },
    openFromOrganization (service) {
      if (service.id === 'billing-explorer') return `${window.location.origin}/#/organization/${this.organizationId}/plan?tabActive=billing`
      if (service.id === 'storage-engines' && this.canStorageEnginesRead) return `${window.location.origin}/databases/#/${this.organizationId}`

      // If there is only one project or if we are inside a project -> we redirect to the service
      if ((this.projects && this.projects.length === 1 && this.projects[0]) || this.projectId) {
        const project = this.project || this.projects[0]
        let serviceId = service.id
        if (serviceId === 'aab') serviceId = 'gab'
        if (['api', 'app'].includes(serviceId)) serviceId = 'appservice'
        if (!project?.services.includes(serviceId)) return null // If service is not project -> we install if (done in method activateService)

        return this.createLink(serviceId, this.projectId || this.projects[0].id)
      }
    },
    getLink (favorite) {
      if (this.projectIsNotReady(favorite)) return

      const { service, projectId } = favorite

      return this.createLink(service, projectId)
    },
    createLink (service, projectId) {
      let link = `${service}/#/${projectId}`

      if (!service) link = `/#/project/${projectId}`

      // If in organization mode, prevent to generate a link for the following services as
      // they are installed within a project and not in the organization
      if (this.config?.ORGANIZATION_MODE && !['control-center', 'cc', 'api', 'app', 'storage-engines'].includes(service)) return

      if (['infra'].includes(service)) link = `${window.location.origin}/#/${service}/${projectId}`
      if (['api', 'app'].includes(service)) link = `${window.location.origin}/#/project/${projectId}`
      if (['cc', 'control-center'].includes(service)) {
        // Prevent having a wrong link like `cc/#/organizationId/organizationId` since organizationId is the same as projectId
        // when in OrganizationMode (when the user is in the IAM organization)
        if (projectId && !this.config?.ORGANIZATION_MODE) link = `${window.location.origin}/cc/#/${this.organizationId}/${projectId}`
      }
      if (service === 'mlm') link = `${window.location.origin}/ml/#/${projectId}`
      if (service === 'appservice' && projectId === this.organizationId) {
        link = null
      } else if (service === 'appservice' && projectId !== this.organizationId) {
        link = `${window.location.origin}/#/project/${projectId}`
      }

      return link
    },
    openItem (favorite) {
      if (this.projectIsNotReady(favorite)) return
      window.localStorage.setItem('tracking-from', 'sidebar favorites')
      window.localStorage.setItem('tracking-from-object', JSON.stringify({
        fromOrganizationId: this.organizationId,
        fromProjectId: this.projectId
      }))
    },
    displayProjectsList (service) {
      const sidebarServiceElement = _get(this.$refs, [`sidebar-service-${service.id}`, 0])
      const projectsListElement = this.$refs['projects-list']
      const sidebarElement = this.$refs.sidebar
      if (sidebarServiceElement && projectsListElement && sidebarElement) {
        const serviceElementBoundings = sidebarServiceElement.getBoundingClientRect()
        const projectsListElementBounding = projectsListElement.getBoundingClientRect()
        const defaultTop = sidebarServiceElement.offsetTop - serviceElementBoundings.height - 90 - sidebarElement.scrollTop

        projectsListElement.style.left = `${serviceElementBoundings.x + serviceElementBoundings.width + 18}px`
        // Get the service top and height and check if the future position of the `projects-list` element will
        // outbounds the screen. Add +2px for security, preventing it to be at the limit of the screen.
        if (((sidebarServiceElement.offsetTop - serviceElementBoundings.height) + projectsListElementBounding.height + 2) > window.innerHeight) {
          projectsListElement.style.top = `${window.innerHeight - projectsListElementBounding.height - 70}px`
        } else {
          projectsListElement.style.top = `${defaultTop}px`
        }
      }
    },
    closeSidebar (event, force) {
      if (event?.target && this.overlap) {
        if (event?.target?.className?.split(' ').includes('fp-sidebar-content')) this.$store.commit('SET_OPEN_SIDE_BAR', !this.openSidebar)
      }
      if (force) this.$store.commit('SET_OPEN_SIDE_BAR', false)
    },
    serviceClassList (service) {
      return {
        active: this.activeService && this.activeService.id === service.id,
        'active-clicked': this.activeService && this.activeService.id === service.id && this.activeServiceClick,
        openedService: this.filteredCurrentService === service.id,
        disabled: service.disabled
      }
    },
    goToHome () {
      this.$analytics.track('Open project', { from: 'sidebar' })
    },
    favoriteProjectExist (projectId) {
      return !!this.$store.getters.DATAPLANT_BY_ID(projectId)
    }
  }
}
</script>

<style lang="less">
.fp-sidebar-content {
  position: relative;
  top: initial !important;
  &:before {
    visibility: hidden;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background-color: fade(@black, 20%);
    width: 100vw;
    height: calc(100vh - 60px);
    z-index: 50;
    transition: opacity 0.3s;
    opacity: 0;
    cursor: pointer;
  }
  &.closed {
    opacity: 0;
    .sidebar {
      margin-left: -280px;
    }
  }
  .sidebar {
    position: relative;
    height: 100%;
    width: 280px;
    padding: 20px 16px 20px 16px;
    background: #fff;
    border-right: 1px solid #E4E7EC;
    transition: margin-left 400ms cubic-bezier(0.5, 1, 0.5, 1);
    opacity: 1;
    z-index: 51;
    overflow: auto;
    overflow-x: hidden;
    &.absolute {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 1000;
      box-shadow: -2px 0px 40px rgba(0, 0, 0, 0.8);
    }

    > .title {
      height: 40px;
      padding: 8px 20px;
      display: flex;
      align-items: center;
      font-size: 16px;
      line-height: 24px;
      color: #3E4550;
      border-radius: 6px;

      .ovh-logo {
        width: 20px;
        margin-right: 15px;
      }
      &.active, &:hover {
        background-color: #E2FAFF;
        font-weight: 600;
      }
    }

    .sub-title {
      height: 28px;
      border-top: 1px solid #E4E7EC;
      border-bottom: 1px solid #E4E7EC;
      padding: 4px 12px;
      font-weight: 600;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: -0.01em;
      color: #0089C0;
      text-transform: uppercase;
      margin-top: 12px;
      margin-bottom: 12px;
    }

    .favorites {
      .favorite {
        display: flex;
        align-items: center;
        padding: 8px 0px 8px 12px;
        height: 40px;
        margin-bottom: 4px;
        cursor: pointer;
        position: relative;

        &:last-of-type {
          margin-bottom: 0;
        }

        .icon-container {
          height: 24px;
          width: 24px;
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 6px;
          border: 1px solid #E4E7EC;
          background: #fff;
          .project-icon {
            font-size: 18px;
            color: white;
          }
        }
        .fp4-angle-right {
          margin-right: 6px;
          font-size: 12px;
          color: #CBD3DB;
        }
        .name-container {
          .service {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #3E4550;
          }
          .project-name {
            font-weight: 600;
            font-size: 10px;
            line-height: 13px;
            text-transform: uppercase;
            color: #97A7B7;
            text-overflow: ellipsis;
            overflow:hidden;
            white-space: nowrap;
            width: 180px;
          }
        }
        .pin-container {
          position: absolute;
          right: 0;
          width: 49px;
          height: 24px;
          top: 8px;
          .fp4-pin-filled {
            display: none;
            font-size: 20px;
            color: #00CCF9;
            position: absolute;
            top: 2px;
            right: 0;
            cursor: pointer;
          }
        }

        &:hover {
          background: #F6F9FC;
          border-radius: 6px;
          .pin-container {
            background: linear-gradient(270deg, #F6F9FC 53.06%, rgba(246, 249, 252, 0) 167.35%);
            .fp4-pin-filled {
              display: block;
              box-shadow: -30px 0 20px 0 #fff;
            }
          }
        }

        &.disabled {
          cursor: not-allowed;
          .service {
            color: #97A7B7 !important;
          }
        }
      }
    }

    .no-elements {
      padding: 0 12px;
      .title-no {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #3E4550;
      }
      .text {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #97A7B7;
      }

      &.project {
        padding: 0 25px 8px 25px;
      }
    }

    .all-services {
      width: 280px;
      height: auto;
      margin-left: -16px; // Need to put scroll at the right & to not loose hover when select project
      margin-right: -16px;
      padding: 0 16px;
      overflow-y: auto;
      overflow-x: hidden;
      .service {
        height: 40px;
        padding: 8px 0 8px 48px;
        display: flex;
        align-items: center;
        cursor: pointer;
        position: relative;
        margin-bottom: 4px;

        img {
          position: absolute;
          left: 13px;
          height: 28px;
        }

        .service-name {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #3E4550;
        }

        &.active {
          background: #F6F9FC;
          border-radius: 6px;
        }
        &.active-clicked {
          .service-name {
            font-weight: 600;
            color: #0089C0;
          }
        }
        &.openedService {
          background: #E2FAFF;
          border-radius: 6px;
          .service-name {
            color: @text-color;
            font-weight: 600;
          }
        }

        &.disabled {
          cursor: not-allowed;
          span {
            color: #97A7B7;
          }
          .fp4-lock-keyhole {
            position: absolute;
            right: 6px;
            top: 11px;
            color: #97A7B7;
            font-size: 18px;
          }
        }
      }
    }
  }
  &.with-overlap {
    &:not(.closed) {
      opacity: 1;
      &:before {
        visibility: visible;
        opacity: 1;
        transition: opacity 200ms linear;
      }
    }
    .sidebar {
      box-shadow: 0 3px 13px 0 rgba(151,167,183,0.3);
    }
  }
  .projects-list {
    position: absolute;
    width: 294px;
    background: #FFF;
    box-shadow: 0px 3px 13px rgba(151, 167, 183, 0.3);
    border-radius: 7px;
    z-index: 1000;
    transition: top 100ms;
  }
}
</style>
